<template>
  <div>
    <b-overlay rounded="sm" :show="CLIENT_EVENTS === null">
      <b-card :title="$t('Search for Events')" class="mb-4">
        <div class="">
          <b-row>
            <b-col md="2">
              <validation-provider #default="validationContext" :name="$t('Search')" rules="min:3">
                <b-form-group :label="$t('Name of the Event')">
                  <b-form-input v-model="search" class="d-inline-block mr-1" :placeholder="$t('Search...')"
                    name="search" :state="getValidationState(validationContext)" />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group :label="$t('Booking type')">
                <v-select :placeholder="$t('Booking type')" v-model="filter.bookingtype"
                  :options="filterOptions.bookingtypes" name="bookingtype" label="name" multiple :clearable="false"
                  :close-on-select="true" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group :label="$t('Event Type')">
                <v-select :placeholder="$t('Event Type')" v-model="filter.eventtypes"
                  :options="filterOptions.eventtypes" name="eventtypes" label="name" multiple
                  :close-on-select="false" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group :label="$t('Host')">
                <v-select :placeholder="$t('Host')" v-model="filter.hostname" :options="filterOptions.hostname"
                  name="hosts" label="name" multiple :close-on-select="false" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group :label="$t('Language')">
                <v-select :placeholder="$t('Language')" v-model="filter.languages" :options="filterOptions.languages"
                  name="languages" label="name" multiple :close-on-select="false" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <h4 class="mb-2"> {{ $t('Events') }}</h4>

      <b-alert variant="warning" :show="(CLIENT_EVENTS && CLIENT_EVENTS.length == 0)">
        <div class="alert-body">
          {{ $t('There are no events available.') }}
        </div>
      </b-alert>

      <b-row v-if="!searchRequest">
        <b-col sm=" 6" md="6" xl="3" class="mb-2" v-for="(event, index) in CLIENT_EVENTS" :key="index">
          <list-card :event="event" />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mb-1" v-if="searchRequest">
        <b-spinner label="Loading..." />
      </div>
      <div class="d-flex justify-content-center">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm" @click="loadMore"
          :class="[(EVENTS_PAGINATION.current_page == EVENTS_PAGINATION.last_page)?'d-none':'']">
          <b-spinner small v-if="loadMoreData" />
          {{ $t('Load more entries') }}
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BOverlay,
  BRow,
  BTable,
  BSpinner,
  BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {mapActions, mapGetters} from "vuex";
import ListCard from "@/views/events/EventList/ListCard";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min } from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardGroup,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRating,
    BTable,
    BCardBody,
    BCardFooter,
    BRow,
    BCarousel,
    BCarouselSlide,
    BCol,
    BOverlay,
    vSelect,
    ListCard,
    ValidationProvider,
    ValidationObserver,
    required,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      loadMoreData: false,
      searchRequest: false,
      page: 1,
      slide: 0,
      sliding: null,
      interval: 3000,
      items: [],
      search: '',
      filter: {

        bookingtype: [{name: this.$t('Dynamic Events'), id: 2}],
        eventtypes: [],
        languages: [],
        hostname: [],
      },
      filterOptions: {
        eventtypes: [],
        languages: [],
        hostname: [],
        bookingtypes: [
          {name: this.$t('Fixed Events'), id: 1},
          {name: this.$t('Dynamic Events'), id: 2}
        ],
      },
    }
  },
  beforeMount() {
    this.RESET_EVENTS()
  },
  mounted() {
    this.GET_LANGUAGES()
    this.getEvents();
  },

  computed: {
    ...mapGetters(['CLIENT_EVENTS', 'LANGUAGES','EVENTS_PAGINATION']),
  },
  watch: {
    // whenever question changes, this function will run
    search(newSearchQuery, oldSearchQuery) {
      if ((newSearchQuery != oldSearchQuery) && !this.searchRequest && newSearchQuery.length >= 3) {
        this.searchRequest = true
        this.getEvents()
      } else {
        if (!newSearchQuery.length) {
          this.searchRequest = true
          this.getEvents()
        }
      }
    },
    'filter.bookingtype'(newBookingTypes, oldBookingTypes) {
      if ((newBookingTypes != oldBookingTypes) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    },
    'filter.eventtypes'(newEventTypes, oldEventTypes) {
      if ((newEventTypes != oldEventTypes) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    },
    'filter.hostname'(newHostName, oldHostName) {
      if ((newHostName != oldHostName) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    },
    'filter.languages'(newEvenLang, oldEventLang) {
      if ((newEvenLang != oldEventLang) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    }
  },

  methods: {
    ...mapActions(['GET_EVENTS', 'GET_LANGUAGES', 'RESET_EVENTS']),
    loadMore() {
      this.loadMoreData = true
      this.getEvents(this.page + 1)
    },
    getEvents(page = 1){
      this.page = page
      let searchParams = {
        search: this.search,
        bookingtype: this.filter.bookingtype,
        eventtypes: this.filter.eventtypes,
        hostname: this.filter.hostname,
        languages: this.filter.languages,
      }
      this.GET_EVENTS({ page, searchParams }).then(() => {
        this.loadMoreData = false
        this.searchRequest = false
        if (this.CLIENT_EVENTS && this.LANGUAGES.length) {
          this.CLIENT_EVENTS.forEach((event) => {
            // event type filter
            let eventFilterData = { id: event.eventtype.id, name: event.eventtype.name }
            let found = false
            this.filterOptions.eventtypes.forEach((filter) => {
              if (filter.id === eventFilterData.id) found = true
            })
            if (!found) {
              this.filterOptions.eventtypes.push(eventFilterData)
            }
            // event type filter
            // language filter
            let foundLanguage = false
            this.filterOptions.languages.forEach((filter) => {
              if (filter.code == event.language) foundLanguage = true
            })
            if (!foundLanguage) {
              let language = this.LANGUAGES.find(x => x.code == event.language);
              this.filterOptions.languages.push(language)
            }
            // language filter
            // host filter
            let foundHost = false
            this.filterOptions.hostname.forEach((filter) => {
              if (filter === event.host) foundHost = true
            })
            if (!foundHost) {
              this.filterOptions.hostname.push(event.host)
            }
            // host filter
          })
          this.filterOptions.eventtypes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          this.filterOptions.languages.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          this.filterOptions.hostname.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

  },

}
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.expert-events-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  min-height: 700px;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.carousel-img > img {
  max-height: 200px !important;
  min-height: 200px !important;
  object-fit: cover !important;
  object-position: center;
  width: 100%;
  height: 100%;
}

.carousel-interval {
  no-wrap: true
}

.slogan-expert {
  min-height: 110px;
}

.event-locationsection {
  min-height: 70px;
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.duration {
  text-align: left !important;
  margin-left: 1px;
}

.clock-icon {
  margin-top: 2px;
}

.host-info {
  text-align: center !important;
}

.credits {
  text-align: right !important;
  margin-left: 60px;
}

.minutes {
  margin-left: 5px;
}

.credits-amount {
  margin-left: 5px;
}

.event-location {
  text-align: center !important;
  justify-content: center;
}

.events-duration {
  margin-left: 10px;
}

.events-credits {
  text-align: right !important;
  margin-right: 10px;
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  visibility: hidden;
  opacity: 1;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
  visibility: visible;
  animation-duration: 1s;
  transition-timing-function: linear;
}

.carousel:hover .carousel-control-prev {
  animation-name: FadeInPrev;
}

.carousel:hover .carousel-control-next {
  animation-name: FadeInNext;
}

@keyframes FadeInPrev {
  0% {
    opacity: 0;
    padding-left: 40px;
  }
  100% {
    opacity: 1;
    padding-left: 0px;
  }
}

@keyframes FadeInNext {
  0% {
    opacity: 0;
    padding-right: 40px;
  }
  100% {
    opacity: 1;
    padding-right: 0px;
  }
}
</style>
